<template>
  <div class="home">

    <h3 class="title">
      Welcome to Making a Moulage
    </h3>

    <p>
      This page will guide you through drafting your very own first draft moulage for making custom fit garments. It will take you step by step in the drafting process, which you will need to follow on your own.
    </p>

    <p>
      Things you'll need:
      <ul>
        <li>
          Paper at least 30" by 30"
          <ul>
            <li>You can a couple pages taped together</li>
          </ul>
        </li>
        <li>Pencil</li>
        <li>Eraser</li>
        <li>Straight ruler (18-24" is suggested)</li>
        <li>French curve (small)</li>
        <li>French curve (large) with distances labeled</li>
        <li>Tracing wheel</li>
      </ul>
    </p>

    <p>
      <router-link to="/draft/moulage">Get started!</router-link>
    </p>
  </div>
</template>

<script>
</script>

<style lang="scss">
.home {
}
</style>
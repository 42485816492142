<template>
  <div class="demo">

    <p>
      <a @click="$router.go(-1)">Back</a>
    </p>

    <h3 class="title">
      Demo Mode Info
    </h3>

    <p>
      This page is still a work in progress. It is currently at a minimal viable product for a demo, but far from completion.
    </p>

    <p>
      The ultimate goal of this website is making home sewing easier for people of all body types, and as that all starts with a proper fitting and pattern making it is good to start with moulage.
    </p>

    <p>
      Once complete the site will be able custom fit patterns for home sewing, because as we all know the sizing chart doesn't fit everyone's body. So one fitting and you can start printing out custom patterns.
    </p>

    <p>
      <strong>The Current Product</strong> will guide you through drafting a moulage for me, as it has not been tested yet with custom measurements. The next step will be instructions for slopers (bodice, arm, pant), for custom measurements.
    </p>
  </div>
</template>

<script>
</script>

<style lang="scss">
.demo {
}
</style>
export const BACKGOUND_COLOR = '#FFF';
export const EULER_SCALE_SMALL = 5.4;
export const EULER_SCALE_STD = 6.75;
export const EULER_SCALE_LARGE = 8.4375;
export const GRID_SIZE = 60;
export const WORKSPACE_WIDTH = 30;
export const WORKSPACE_HEIGHT = 30;

export let EULER_MAX_LENGTH = {}
EULER_MAX_LENGTH[EULER_SCALE_SMALL] = 6.42372;
EULER_MAX_LENGTH[EULER_SCALE_STD] = 8.02965;
EULER_MAX_LENGTH[EULER_SCALE_LARGE] = 10.03706;

export const GUIDE_LINE = {color: "#555", lineWidth: 1};
